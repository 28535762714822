<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <!-- //Edit POP UP -->
    <div class="demo-alignment">
      <vs-popup
        class="holamundo"
        title="إنشاء كوبون"
        :active.sync="popupActive"
      >
        <div class="flex flex-col justify-items-center">
          <vs-checkbox class="mb-3" v-model="manual_code">
            هل تريد ادخال رمز الكوبون يدوياّ؟
          </vs-checkbox>
          <div v-show="manual_code" class="vx-col w-1/2 w-full mb-2">
            <vs-input
              type="number"
              class="w-full"
              label-placeholder="رمز الكوبون"
              v-model="current_coupon.code"
              data-vv-scope="code"
              data-vv-as="رمز الكوبون"
              name="coupon_code"
              v-validate="'required|length:8|alpha_num'"
            />
            <span class="text-danger text-sm" v-show="errors.has('code.coupon_code')">
              {{ errors.first("code.coupon_code") }}
            </span>
          </div>
          <div v-show="!manual_code" class="vx-col w-1/2 w-full mb-2">
            <vs-input
              type="number"
              class="w-full"
              label-placeholder="عدد الكوبونات المطلوبة"
              v-model="current_coupon.n_codes"
              data-vv-as="عدد الكوبونات"
              data-vv-scope="number_codes"
              name="number"
              v-validate="'required|numeric'"
            />
            <span class="text-danger text-sm" v-show="errors.has('number_codes.number')">
              {{ errors.first("number_codes.number") }}
            </span>
          </div>
          <div class="vx-col w-1/2 w-full mb-2 flex align-center">
            <vs-input
              type="number"
              class="w-4/5"
              label-placeholder="القيمة"
              v-model="current_coupon.discount"
              data-vv-scope="coupon_data"
              data-vv-as="القيمة"
              name="discount"
              v-validate="'required|numeric'"
            />
             <span class="text-danger text-sm" v-show="errors.has('coupon_data.discount')">
              {{ errors.first("coupon_data.discount") }}
            </span>
            <div class="w-1/5 m-3">
            <vs-label  class="vs-input--label" for="is_percentage"> نسبة مئوية؟</vs-label>
            <vs-checkbox
            id="is_percentage"
             v-model="current_coupon.is_percentage"

              >
              </vs-checkbox>
            </div>

          </div>
          <div class="vx-col w-1/2 w-full">
            <vs-input
              type="number"
              class="w-full"
              label-placeholder="عدد مرات الإستخدام"
              v-model="current_coupon.usage_count"
              data-vv-scope="coupon_data"
              data-vv-as="عدد مرات الإستخدام"
              name="usage_count"
              v-validate="'required|numeric'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('coupon_data.usage_count')"
            >
              {{ errors.first("coupon_data.usage_count") }}
            </span>
          </div>

          <div class="mb-6">&#8203;</div>
          <vs-button
            @click="submit()"
            color="success"
            type="filled"
            icon-pack="feather"
            icon="icon-check"
          >
            حفظ</vs-button
          >
        </div>
      </vs-popup>
    </div>
    <!-- ///////////////////// -->

    <vs-table
      v-if="coupons.length"
      ref="table"
      :max-items="itemsPerPage"
      search
      :data="coupons"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div
          class="flex flex-wrap-reverse items-center data-list-btn-container"
        >
          <!-- ADD NEW -->
          <vs-button
              @click="addNewData"
              class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 ">إنشاء كوبون</span>
            </vs-button>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        >
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                coupons.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : coupons.length
              }}
              of {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="code">رقم الكوبون</vs-th>
        <vs-th sort-key="value">القيمة</vs-th>
        <vs-th sort-key="usage_count">المتبقي</vs-th>
        <vs-th sort-key="is_used">حالة الكوبون</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.code }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.value }} {{ tr.is_percentage ? '%' : 'LYD' }}</p>
            </vs-td>

            <vs-td>
              <p class="product-order-status">{{ tr.usage_count }}</p>
            </vs-td>
            <!-- <vs-td>
              <vs-chip class="product-order-status">
                {{
                  `${new Date(tr.created_at).getFullYear()}-${
                    new Date(tr.created_at).getMonth() + 1
                  }-${new Date(tr.created_at).getDate()}`
                }}
              </vs-chip>
            </vs-td> -->

            <vs-td>
              <vs-chip
                :color="getCouponsStatusColor(tr.status)"
                class="product-order-status"
                v-if="tr.status == 'true'"
              >
                {{ "فعال" }}</vs-chip
              >
              <vs-chip
                :color="getCouponsStatusColor(tr.status)"
                class="product-order-status"
                v-else
              >
                {{ "منتهي" }}</vs-chip
              >
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="confirm(tr.id)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
       </template>

    </vs-table>
     <EmptyState :link="addNewData" page="كوبونات" display="true" v-else></EmptyState>
  </div>
</template>

<script>
import vSelect from "vue-select";
import EmptyState from "@/views/emptyState.vue";
import {mapActions} from "vuex";
export default {
  components: {
    vSelect,
    EmptyState
  },
  data() {
    return {
      manual_code: false,
      coupon_id: "",
      current_coupon: {
        is_percentage: false,
        code: null,
        n_codes: null,
        discount: null,
        usage_count: null,
      },
      current_Page: 1,
      itemsPerPage: 5,
      lastPage: 0,
      totalItems: 0,
      popupActive: false,
      loading: true,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.coupons.length;
    },
    coupons(){
      return this.$store.state.coupons.coupons
    }
  },
  methods: {
    ...mapActions('coupons', [
      'fetchCoupons',
      'createCoupon',
      'deleteCoupon',
    ]),
    addNewData(){
      // this.popupActive = true
      this.$router.push('/new-coupon');
    },
    initValues(){
      this.manual_code = false
      this.current_coupon = {
        is_percentage: false,
        code: null,
        n_codes: null,
        discount: null,
        usage_count: null,
      }
    },
    async submit() {
      let result;
      if(this.manual_code){
        await this.$validator.validateAll("coupon_data");
        result = await this.$validator.validateAll('code')
      }
      else if(!this.manual_code){
        await this.$validator.validateAll("coupon_data");
        result = await this.$validator.validateAll('number_codes')
      }



      if (result) {
        var bodyFormData = new FormData();
        if(this.current_coupon.code)bodyFormData.set("code", this.current_coupon.code);
        bodyFormData.set("is_percentage", this.current_coupon.is_percentage ? 1 :0);
        bodyFormData.set("coupon_count", this.current_coupon.n_codes || 1);
        bodyFormData.set("value", this.current_coupon.discount);
        bodyFormData.set("usage_count", this.current_coupon.usage_count);

        this.createCoupon(bodyFormData)
          .then((response) => {
            this.popupActive = false;
            this.initValues();
            this.fetchCoupons()
            this.successDialog(response.data.message);
          })
          .catch((error) => {
            this.errorDialog(error);
          });
      }
    },
    confirm(id) {
      this.coupon_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: "هل انت متأكد من أرشفة هذا الكوبون؟",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.deleteData,
      });
    },
    deleteData() {
      this.deleteCoupon(this.coupon_id)
        .then((response) => {
         this.successDialog(response.data.message)

        })
        .catch((error) => {
          this.errorDialog(error)
        });
    },

    getCouponsStatusColor(status) {
      if (status == 'false') return "danger";
      if (status == 'true') return "success";
      return 'primary';
    },
  },
  created() {
    this.fetchCoupons()
  },
};
</script>
